<template>
  <div class="py-16 pb-0">
    <div class="container mx-auto ">
      <h1 class="text-4xl mb-4 font-merri text-center mb-16">
        Feeding Guidelines
      </h1>
      <div class="flex justify-center mb-4 text-gray-700">
        <div class="flex p-2 bg-gray-200 rounded-lg space-x-2">
          <div
            @click="currentCategory = 'adult'"
            class="w-32 py-2 px-4  rounded text-center hover:bg-gray-300 cursor-pointer"
            :class="{
              'bg-gray-600 text-white hover:bg-gray-600 cursor-default':
                currentCategory === 'adult'
            }"
          >
            Adult
          </div>
          <div
            @click="currentCategory = 'kitten'"
            class="w-32 py-2 px-4 rounded text-center hover:bg-gray-300 cursor-pointer"
            :class="{
              'bg-gray-600 text-white hover:bg-gray-600 cursor-default':
                currentCategory === 'kitten'
            }"
          >
            Kitten
          </div>
        </div>
      </div>
      <div class="text-center text-gray-600 mb-4">Unit = 1 Pouch/Tray/Can</div>
      <div
        class="flex flex-col sm:flex-row border-2 border-t-0 border-gray-400"
      >
        <div
          v-for="(guide, index) in guidelines[currentCategory]"
          :key="`guide-${index}`"
          class="flex-grow text-center border-t-2 border-gray-400"
          :class="{
            'sm:border-r-2 border-gray-400': index < guidelines.length - 1
          }"
        >
          <div
            class=" py-4 border-b-2 border-gray-400 h-24 flex items-center justify-center"
          >
            <div>
              <div class="text-xl font-semibold">{{ guide.type }}</div>
              <div class="italic">{{ guide.weight }}</div>
            </div>
          </div>
          <div class="bg-gray-100 py-4">
            <div class="text-gray-600">Wet Food Only</div>
            <div class="font-semibold">
              {{ guide.wet }}
              {{ guide.wet !== '1' ? 'Units' : 'Unit' }}
            </div>
          </div>
          <div class="py-4">
            <div class="text-gray-600">Wet &amp; Dry Food</div>
            <div class="font-semibold">
              {{ guide.wetDry.wet }}
              {{ guide.wetDry.wet !== '1' ? 'Units' : 'Unit' }}
            </div>
            <div>+</div>
            <div class="font-semibold">{{ guide.wetDry.dry }}g dry</div>
          </div>
          <div class="bg-gray-100 py-4  border-gray-400">
            <div class="text-gray-600">Dry Food Only</div>
            <div class="font-semibold">{{ guide.dry }}g</div>
          </div>
        </div>
      </div>
      <h2
        class="px-8   mt-4 font-merri text-center mb-4 bg-yellow-100 p-4 border-2 border-yellow-300 mx-auto px-6 rounded "
      >
        We recommend feeding in line with your current feeding routine. (E.g. if
        2 pouches, feed 2 pouches).
      </h2>

      <p class="mt-4 text-center">
        The above is a guide only. Monitor and if they are losing or putting on
        excess weight adjust the amount you feed accordingly.
      </p>
      <div class="w-full sm:w-2/3 text-center mx-auto">
        Store in a cool, dry place. Once opened, refrigerate and use within 2
        days. Best served at room temperature. Always ensure fresh water is
        available. Transition the new food over a week, gradually introducing
        bit by bit!
      </div>
      <div class="flex  flex-col sm:flex-row py-8 sm:py-16  items-center">
        <div class="w-full sm:w-1/3 sm:mr-24 flex-none sm:-mt-24 relative">
          <div
            class="p-4 rounded-lg absolute inset-0 rotate-mini-back bg-blue-200 transform "
          ></div>
          <div
            class="relative z-10 p-6 sm:p-8 sm:px-12 border-4 rounded-lg border-blue-300"
          >
            <div class="font-semibold text-lg sm:text-xl ">
              <div class="flex items-center  pt-6 ">
                <div class="text-5xl text-blue-500 px-6 pl-0 text-center">
                  <font-awesome-icon :icon="['fal', 'weight']" />
                </div>
                <div>
                  How to find the weight of your cool cat
                </div>
              </div>
            </div>

            <p class="mt-4">
              Stand on bathroom scales with and without your cat and measure the
              difference (or if you think the scales might tell fibs for your
              weight, just get someone else to do it!).
            </p>
            <p>
              If you don’t have any scales, make a rough estimation of your cats
              size and adjust from there.
            </p>
          </div>
        </div>
        <div class="flex-grow relative mt-16 sm:mt-48">
          <div
            class="p-4 rounded-lg absolute inset-0 rotate-mini bg-teal-200 transform "
          ></div>
          <div
            class="relative z-10 p-8 px-12 border-4 rounded-lg border-teal-300"
          >
            <div class="font-semibold text-xl ">
              <div class="flex items-center">
                <div class="text-5xl text-teal-500 px-6 pl-0 text-center">
                  <font-awesome-icon :icon="['fal', 'dice']" />
                </div>
                <div>
                  For all those fussy cats...
                </div>
              </div>
            </div>

            <p class="mt-4">
              If your cat is at all fussy, here’s how to give them the best
              chance with new food.
            </p>

            <div class="font-semibold">
              Method 1...
            </div>
            <div class="mb-4">
              Phase in gradually. Mix the new food into their current food bit
              by bit. Starting with just a small amount and increasing slowly.
            </div>
            <div class="font-semibold">
              Method 2...
            </div>
            <div class="mb-8">
              If the above doesn’t work, try placing a small amount of the new
              food chopped up in a completely separate dish and well away from
              their usual food.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mx-auto py-12">
      <action-ctas />
    </div>
  </div>
</template>

<script>
import ActionCtas from '@/components/ActionCtas';
export default {
  metaInfo: {
    title: 'Feeding Guidelines - The Cool Cat Club'
  },
  components: {
    ActionCtas
  },
  props: {
    category: {
      type: String,
      default: 'adult'
    }
  },
  data() {
    return {
      currentCategory: this.category,
      guidelines: {
        adult: [
          {
            type: 'Smaller Cat',
            weight: '(Under 2.5kg)',
            wet: '1 - 2',
            wetDry: {
              wet: '1',
              dry: '20'
            },
            dry: '25 - 40'
          },
          {
            type: 'Average Cat',
            weight: '(2.5kg - 4kg)',
            wet: '2 - 3',
            wetDry: {
              wet: '1 - 2',
              dry: '25'
            },
            dry: '40 - 60'
          },
          {
            type: 'Larger Cat',
            weight: '(4kg +)',
            wet: '2 - 4',
            wetDry: {
              wet: '2',
              dry: '25 - 40'
            },
            dry: '60 - 75'
          }
        ],
        kitten: [
          {
            type: '2 - 4 Months',
            weight: '',
            wet: '2',
            wetDry: {
              wet: '1',
              dry: '15 - 25'
            },
            dry: '40 - 60'
          },
          {
            type: '5 - 8 Months',
            weight: '',
            wet: '2 - 4',
            wetDry: {
              wet: '1 - 2',
              dry: '20 - 50'
            },
            dry: '40 - 70'
          },
          {
            type: '9 - 12 Months',
            weight: '',
            wet: '2 - 4',
            wetDry: {
              wet: '1 - 2',
              dry: '20 - 60'
            },
            dry: '40 - 80'
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.rotate-mini {
  transform: rotate(4deg);
}
.rotate-mini-back {
  transform: rotate(-6deg);
}
</style>
